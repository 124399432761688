import {useEffect, useState} from "react";
import Switch from "../../components/Switch/Switch";
import styles from "../Stores/Stores.module.css";
import axios from "axios";
import UseGeneral from "../../hooks/useGeneral";
import toast from "react-hot-toast";
import {BASEURL} from "../../API/BaseUrl";
import {useNavigate} from "react-router-dom";
import {ChevronLeft} from "../../assets/svgIcons/svgIcons";
import "./style.css";
import { useDispatch } from "react-redux";
import { getRender } from "../../redux/rerednerSlice";

export default function StartStores() {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const {language} = UseGeneral();
  const [selectedMarkets , setSelectedMarkets] = useState([])


  const getStores = async () => {
    setLoading(true);

    const dataset = {
      city_id: JSON.parse(localStorage.getItem("ksbCity"))?.city_id,
    };

    await axios
      .post(`${BASEURL}products/select_markts.php`, JSON.stringify(dataset))
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setStores(res?.data?.message[0].markts);
          setSelectedMarkets(res?.data?.message[0].markts);
          console.log(res?.data?.message[0].markts);
        } else {
          toast.error("هناك مشكلة ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getStores();
  }, []);

  const navigate = useNavigate();
  
  const dispatch = useDispatch()


  const onSelectMarket = (e , market)=> {

    const state = e.target.checked


     if(state){
        setSelectedMarkets([...selectedMarkets , market])
      }else{
       setSelectedMarkets(selectedMarkets.filter(m => m.market_id != market.market_id ))
     }
  }

  useEffect(()=>{
    console.log(selectedMarkets)
  },[selectedMarkets])

 const onSaveStores = ()=>{

  console.log(selectedMarkets.length)


  if(selectedMarkets.length == 0){
    toast.error(language == "ar" ? "قم باختيار متجر واحد على الاقل" :"Select at least one store")
    return
  }
  localStorage.setItem("ksbStores" , JSON.stringify(selectedMarkets))
  
  dispatch(getRender())
  navigate("/")
 }


  return (
    <>
      <div className='header_start_icon' onClick={() => navigate(-1)}>
        {ChevronLeft}
      </div>
      <div className={styles.storesContainer}>
        {stores && stores.length >= 1 && Array.isArray(stores) ? (
          stores.map((store, index) => {
            return (
              <div>
                <p>
                  {language == "ar"
                    ? store.market_name_ar
                    : store.market_name_en
                    }
                </p>
                <Switch checked={selectedMarkets.find(item => item.market_id == store.market_id)} onChange={(e)=>onSelectMarket(e , store)} id={index} />
              </div>
            );
          })
        ) : (
          <div className=''>{language == "ar" ? "لا يوجد بيانات":"There are no data"}</div>
        )}
        <div className='d-flex align-items-center justify-content-between'>
          <button
            onClick={()=> onSaveStores()}
            className='btn text-light'
            style={{
              backgroundColor: "#3b069d",
            }}
          >
            حفظ
          </button>
        </div>
      </div>
    </>
  );
}
