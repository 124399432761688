import { BiLogoTelegram } from "react-icons/bi";
import { BsStar, BsStars, BsTwitter } from "react-icons/bs";
import styles from "./ContactUs.module.css";
import UseGeneral from "../../hooks/useGeneral";

export default function ContactUs() {

  const {language} = UseGeneral()


  return (
    <div className={styles.contactUsContainer}>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
          />
        </svg>
        <p>

        {
          language == "ar" ? "على الإيميل الخاص بنا":"On our E-mail"
        }

          </p>
      </div>

      <div>
        <BsTwitter />

          <p>
        {language == "ar" ? "تويتر"  : "Twitter"}


          </p>


      </div>

      <div>
        <BiLogoTelegram />


          <p>
        {language == "ar" ? "تيليجرام"  : "Telegram"}


          </p>



      </div>

      <div>
        <BsStar />
          <p>
        {language == "ar" ? "قيم التطبيق"  : "Rate the app"}
          </p>
      </div>
    </div>
  );
}
