import {useEffect, useState} from "react";
import styles from "./ChooseCountry.module.css";
import "./style.css";
import useSelection from "antd/es/table/hooks/useSelection";
import axios from "axios";
import {BASEURL} from "../../API/BaseUrl";
import toast from "react-hot-toast";
import UseGeneral from "../../hooks/useGeneral";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getRender } from "../../redux/rerednerSlice";

export default function ChooseCountry({getSelectedCity}) {
  const [selectedCity, setSelectedCity] = useState("");
  const {language} = UseGeneral();
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const getAllCities = async () => {
    setLoading(true);

    await axios
      .get(`${BASEURL}products/select_cities.php`)
      .then((res) => {
        if (res.data.status == "success") {
          setCities(res?.data?.message);
        } else {
          toast.error("هناك مشكلة ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  const getStoredCity = () =>{
    const storedCity = JSON.parse(localStorage.getItem("ksbCity")) || ""
    setSelectedCity(storedCity)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    getAllCities();
    getStoredCity();
  }, []);

  const chooseAllStores =  async(city_id)=>{
      setLoading(true);
      const dataset = {
        city_id
      };
  
      await axios
        .post(`${BASEURL}products/select_markts.php`, JSON.stringify(dataset))
        .then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            localStorage.setItem("ksbStores" , JSON.stringify(res?.data?.message[0].markts));
            console.log(res?.data?.message[0].markts);
            dispatch(getRender())

             
          } else {
            toast.error("هناك مشكلة ما");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setLoading(false);
        });
  }

  const onSaveCity = () =>{
    if(!selectedCity){
      toast.error("قم باختيار مدينة")
      return
    }


    localStorage.setItem("ksbCity" , JSON.stringify(selectedCity))
    chooseAllStores(selectedCity.city_id);



    navigate("/settings/store")
  }



  return (
    <>
      <div className={styles.countriesContainer}>
        {cities.map((c, i) => (
          <div
            className={`country ${selectedCity.city_id == c.city_id ? " active " : ""}`}
            onClick={() => setSelectedCity(c)}
            key={i}
          >
            <p>{language == "ar" ? c.name_ar : c.name_en}</p>
          </div>
        ))}
      </div>

      <div className='bottom_sanve_btn'>
        <button className='btn text-light' onClick={()=>onSaveCity()} style={{backgroundColor: "#540965"}}>
          {language == "ar" ? "حفظ" : "save"}
        </button>
      </div>
    </>
  );
}
