import { SettingsCog, Shopping, favIcon, fork_spoonIcon, search } from "../../assets/svgIcons/svgIcons";
import "./style.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import UseGeneral from "../../hooks/useGeneral";
import axios from "axios";
import { BASEURL } from "../../API/BaseUrl";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useUserId from "../../hooks/useUserId";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = UseGeneral();
  const [favLength, setFavLength] = useState("0")


  const footerItems = [
    {
      id: 1,
      name: language == "ar" ? "مقاضي" : "Chefs",
      icon: fork_spoonIcon,
      pathname: "/",
    },
    {
      id: 2,
      name: language == "ar" ? "المفضلة" : "Favorits",
      icon: favIcon,
      pathname: "/cart",
    },
    {
      id: 3,
      name: "حسابي",
      name: language == "ar" ? "حسابي" : "Account",
      icon: SettingsCog,
      pathname: "/settings",
    },
  ];
  const [searchParam, setSearchParam] = useSearchParams()
  const { userId } = useUserId()
  const getCartData = async () => {
    const dataSet = {
      user_id: userId,
      language_type: language == "ar" ? "arabic" : "english"

    };
    await axios
      .post(
        `${BASEURL}products/select_user_favorite.php`,
        JSON.stringify(dataSet)
      )
      .then((res) => {
        if (res.data.status == "success") {
          setFavLength(res.data.message.length)
          console.log(res.data.message)

        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
      });
  };

  const rerender = useSelector(state => state.rerender.rerender)

  useEffect(() => {
    if (userId)
      getCartData()
  }, [rerender, userId])



  return (
    <div
      className='footer_container'

    >
      {footerItems?.map((item) => {
        return (
          <div
            className={`footer_item ${location.pathname == item.pathname ? "active" : ""}`}

            key={item.id}
            onClick={() => navigate(item.pathname)}
          >
            <div className='footer_icon'>{item.icon}</div>
            <div className='footer_item_text'>{item.name}</div>
            {
              item.pathname == "/cart" &&
              <div className="footer_padge">{favLength}</div>
            }
          </div>
        );
      })}
    </div>
  );
};

export default Footer;
