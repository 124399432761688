import React from 'react'
import './style.css'
import {SearchRight } from '../../assets/svgIcons/svgIcons'

const SearchInput = ({
  placeholder,
  value,
  containerStyles,
  onChange,
  inputStyles,
  ...props
}) => {
  return (
    <div className="searchInput" style={{width:'100%'}} >
      <div className='searchIcon'>{SearchRight}</div>
      <input value={value} style={inputStyles} {...props} type="text" onChange={onChange} placeholder={placeholder ||  "Search..."} />
    </div>
  )
}

export default SearchInput
