import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

function useUserId() {
  const [userId, setUserId] = useState();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const userIdFromQuery = queryParams.get("userId");
      console.log(window.location.search);
      if (userIdFromQuery) {
        setUserId(userIdFromQuery);
      } else {
        setUserId(0);
      }

      console.log("userIdFromQuery", userIdFromQuery);
    }, 190);
  }, [location]);

  return {
    userId,
  };
}

export default useUserId;
