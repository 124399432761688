import {Link, useNavigate} from "react-router-dom";
import styles from "./Settings.module.css";
import {BsTranslate} from "react-icons/bs";
import { LanguageIcon } from "../../assets/svgIcons/svgIcons";
import UseGeneral from "../../hooks/useGeneral";

export default function Settings() {
  const navigate = useNavigate();

  const {language} = UseGeneral()




  return (
    <div className={styles.settingsContainer}>
      <div onClick={() => navigate("/settings/store")} className={styles.settingCard}>
        <div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z'
            />
          </svg>
        </div>
        <div className={styles.settingsContent}>
          <h3>{language == "ar" ? "المتاجر":"stores"}</h3>
          <p>

            {
              language == "ar" ? 
              " أسواق السوبر ماركت التي تعرض بالقائمه"
              :
              "Supermarkets that are displayed on the list"
            }
          </p>
        </div>
      </div>

      <div
        onClick={() => navigate("/settings/chooseLanguage")}
        className={styles.settingCard}
      >
        <div>{LanguageIcon}</div>
        <div className={styles.settingsContent}>
          <h3>
            {
              language == "ar" ? 
              "اختيار اللغة"
              :
              "Choose the language"
            }
          </h3>
          <p>{

              language == "ar"?
               "العربية":
               "English"
            }</p>
        </div>
        <div>{/* <BsTranslate /> */}</div>
      </div>

      <div
        onClick={() => navigate("/settings/chooseCountries")}
        className={styles.settingCard}
      >
        <div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'
            />
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z'
            />
          </svg>
        </div>
        <div className={styles.settingsContent}>
          <h3>
            {
              language == "ar"? "تحديد المدينة":"Select the city"
            }

          </h3>
          <p>{JSON.parse(localStorage.getItem("ksbCity"))[ language =="ar" ? "name_ar" : "name_en"]}</p>
        </div>
      </div>

      <div
        onClick={() => navigate("/settings/priceAlerts")}
        className={styles.settingCard}
      >
        <div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5'
            />
          </svg>
        </div>
        <div className={styles.settingsContent}>
          <h3>

            {
              language == "ar" ? "تنبيهات الأسعار": "Price alerts"
            }

          </h3>
          <p
          >
            {
              language == "ar" ? "التحكم بتنبيه الخصومات" : "Control discount alerts"
            }
          </p>
        </div>
      </div>

      <div className={styles.settingCard}>
        <div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
            />
          </svg>
        </div>
        <div className={styles.settingsContent}>
          <h3>
              {
                language == "ar" ? "الأسعار والعروض":"Prices and offers"
              }
             </h3>
          <p>
            {
              language == "ar" ? "أسعار وعروض الفرع + الأونلاين الحصرية":"Exclusive branch + online prices and offers"
            }
          </p>
        </div>
      </div>

      <div
        onClick={() => navigate("/settings/contactUs")}
        className={styles.settingCard}
      >
        <div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z'
            />
          </svg>
        </div>
        <div className={styles.settingsContent}>
          <h3>
            {
              language == "ar" ? "تواصل معنا":"Contact us"
            }
          </h3>
          <p> 
            {language == "ar" ? "للمقترحات والتقييم" : "For suggestions and evaluation"}
          </p>
        </div>
      </div>

      <div className={styles.settingCard}>
      <div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z'
            />
          </svg>
        </div>
        <div className={styles.settingsContent}>
          <h3> {language == "ar" ? "شارك التطبيق":"Share the app"}</h3>
          <p>{language == "ar" ? "أخبر أصدقائق عن التطبيق":"Tell your friends about the App"}</p>
        </div>
        
      </div>

      <div
        onClick={() => navigate("/settings/privacyPolicy")}
        className={styles.settingCard}
      >
         <div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z'
            />
          </svg>
        </div>
        <div className={styles.settingsContent}>
          <h3>{language == "ar" ? "سياسة الخصوصية" : "Privacy Policy"}</h3>
        </div>
       
      </div>

      <div className={styles.settingCard}>
      <div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z'
            />
          </svg>
        </div>
        <div className={styles.settingsContent}>
          <h3> {

              language == "ar" ? "الشروط والأحكام":"Terms and Conditions"

            } </h3>
        </div>
       
      </div>

      <div className={styles.settingCard}>
      <div>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z'
            />
          </svg>
        </div>
        <div className={styles.settingsContent}>
          <h3>{language == "ar" ? "الإصدار" :"Release"}</h3>
        </div>
      
      </div>
    </div>
  );
}
