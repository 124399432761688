import React from 'react';
import './style.css';
import UseGeneral from '../../../hooks/useGeneral';

const AddToCartButton = ({onClick , isExist}) => {

  const {language} = UseGeneral()

  return (
    <>
      <div onClick={onClick} className='add_to_cart_btn' >

    {
      language == "ar" ?
        isExist ? 
        "حذف من المفضلة"
        :

       "أضف إلى المفضلة"
       
       
       :

       isExist ? 
       "Delete from favorits"
          :
       
       "Add to favorits"
    }


      </div>
    </>
  )
}
export default AddToCartButton
