import { useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import AppRoutes from "./Routes/Routes";
import DefaultLayout from "./layouts/defaultLayout";
import toast, { Toaster } from "react-hot-toast";
import { useEffect } from "react";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('userId', '3'); // Set the query parameter

    // Using history API directly if history is available
    if (window.history) {
      window.history.replaceState(null, '', newUrl.toString());
    }

    // Example of a side effect with a timeout
    setTimeout(() => {
      // Your side effect code here
      console.log('Side effect triggered after URL update');
    }, 100);
  }, [location]);
  return (
    <div className="App">
      <DefaultLayout>
        {<AppRoutes />}
        <Toaster containerClassName="toastCon" />
      </DefaultLayout>
    </div>
  );
}

export default App;
