import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { change } from "../redux/lnaguageSlice";

const UseGeneral = () => {
  const language = useSelector((state) => state?.language?.language);

  const dispatch = useDispatch();
  const [data, setData] = useState();
  const changeLanguage = (payload) => {
    dispatch(change(payload));
  };

  const siteData = useSelector((state) => state?.site?.siteData);

  useEffect(() => {
    if (language.toLowerCase() == "ar") {
      document.body.classList.add("arVersion");
      document.body.classList.remove("enVersion");
    } else {
      document.body.classList.add("enVersion");
      document.body.classList.remove("arVersion");
    }
  }, [language]);
  useEffect(() => {
    if (siteData?.logo) {
      setData(siteData);
    }
  }, [siteData]);
  return {
    language: language?.toLowerCase(),
    changeLanguage: changeLanguage,
    data: data,
  };
};

export default UseGeneral;
