import UseGeneral from '../../hooks/useGeneral';
import styles from './ChooseLanguage.module.css';

import "./style.css"

export default function ChooseLanguage() {

  const {language , changeLanguage} = UseGeneral()


  const languages = [
    {
      id:"1",
      lang:language == "ar" ? "العربية":"Arabic",
      name:'ar',
    },
    {
      id:"2",
      lang:language == "ar" ? "الإنجليزية":"English",
      name:'en',
    },
  ]

  console.log(language)


  return (
    <div className={styles.chooseLanguageContainer} >


      {
        languages?.map((lang , index)=>{
          return(
            <div
              onClick={()=>changeLanguage(lang.name)}
            key={index} className={`
              
              ${
                lang.name == language ? "active_row":""
              }


            `}>
                <p>{lang.lang}</p>
            </div>

          )
        })
      }


       
    </div>
  )
}
