import React, {useState, useEffect} from "react";
import SupCatsSlider from "./../../components/categoryProducts/SupCatsSlider/SupCatsSlider";
import SupCatProductsSection from "../../components/categoryProducts/SupCatProductsSection/SupCatProductsSection";
import prod from "../../assets/images/prod_1.png";

import "./style.css";
import axios from "axios";
import {useParams} from "react-router-dom";
import toast from "react-hot-toast";
import {BASEURL} from "./../../API/BaseUrl";
import UseGeneral from "../../hooks/useGeneral";
const CategoriesProds = () => {
  const [subCats, setSubCats] = useState([]);
  const [originalSubCats, setOriginalSubCats] = useState([]);
  const [loading, setLoading] = useState(false);

  const {id} = useParams();
  const {language} = UseGeneral();

  const selectSubCategories = async () => {
    setLoading(true);
    const dataSet = {
      category_id: +id,
      language_type: language == "ar" ? "arabic" : "english",
    };
    await axios
      .post(`${BASEURL}products/select_home_data.php`, JSON.stringify(dataSet))
      .then((res) => {
        if (res.data.status == "success") {
          setSubCats(res?.data?.message);
          setOriginalSubCats(res?.data?.message);
          console.log(res.data.message);
        } else {
          toast.error("هناك مشكلة ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    selectSubCategories();
  }, []);

  console.log(
    subCats?.sub_category_names?.map((subItem, index) => {
      const products = subCats?.products.filter(
        (item) => item.sub_category_name == subItem?.sub_category_name
      );

      return {
        supCategory: subItem?.sub_category_name,
        products,
      };
    })
  );

  return (
    <div className='cat_prods_container'>
      <div className='cat'></div>
      {loading ? (
        <div className='d-flex align-items-center justify-content-center'>
          <div class='spinner-border text-primary m-2' role='status'>
            <span class='visually-hidden text-center'>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className='mb-t'>
            <SupCatsSlider data={subCats?.sub_category_names} />
          </div>
          <div className='sub_cats_container'>
            {subCats?.sub_category_names
              ?.map((subItem, index) => {
                const products = subCats?.products.filter(
                  (item) => item.sub_category_name == subItem?.sub_category_name
                );

                return {
                  supCategory: subItem?.sub_category_name,
                  products,
                };
              })

              .map((item, index) => {
                return (
                  <SupCatProductsSection
                    data={item}
                    id={String(item.supCategory)}
                    key={index}
                  />
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default CategoriesProds;
