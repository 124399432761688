import React, { useEffect, useState } from "react";
import "./style.css";
import coffee from "../../../assets/images/coffee.png";
import cans from "../../../assets/images/pop-cans.png";
import vigitables from "../../../assets/images/vigitables.png";
import breads from "../../../assets/images/breads.png";
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import { BASEURL } from "../../../API/BaseUrl";
import toast from "react-hot-toast";
import UseGeneral from "../../../hooks/useGeneral";

const Categories = () => {
  // const data = [
  //   {
  //     id: 1,
  //     image: coffee,
  //     text: "قهوة",
  //   },
  //   {
  //     id: 2,
  //     image: breads,
  //     text: "الخضراوات والفواكه",
  //   },
  //   {
  //     id: 3,
  //     image: vigitables,
  //     text: "الخضراوات والفواكه",
  //   },
  //   {
  //     id: 4,
  //     image: cans,
  //     text: " المشروبات",
  //   },
  //   {
  //     id: 5,
  //     image: coffee,
  //     text: "قهوة",
  //   },
  //   {
  //     id: 6,
  //     image: breads,
  //     text: "الخضراوات والفواكه",
  //   },
  //   {
  //     id: 7,
  //     image: vigitables,
  //     text: "الخضراوات والفواكه",
  //   },
  //   {
  //     id: 8,
  //     image: cans,
  //     text: " المشروبات",
  //   },
  // ];


  const [data ,  setData] = useState([])
  const [originalData , setOriginalData] = useState([]);
  const [loadig , setLoading] = useState(false)

  const selectCategories = async () =>{
    setLoading(true);
      
      await axios
        .get(`${BASEURL}products/select_categories.php`)
        .then((res) => {
          if (res.data.status == "success") {
            setData(res?.data?.message);
            setOriginalData(res?.data?.message);
            console.log(res.data.message);
          } else {
            toast.error("هناك مشكلة ما");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setLoading(false);
        });
    };

  useEffect(()=>{
    selectCategories()
  },[]) 







  const navigate  = useNavigate();
  const {language} = UseGeneral()
  const {id} = useParams()

  return (
    <div className='categories_container'>
      <div className="fs-3 my-3">{language == "ar" ? "مقاضي" : "Categories"}</div>
      <div className="first_four">
        {data && data.length >= 1 && Array.isArray(data)
          ? data?.slice(0,4)?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`category_card ${
                    index == 1 || index == 2 ? "big" : "small"
                  }`}

                  onClick={()=> navigate(`/cats_prods/${item?.category_id}`)}
                >
                  <div className='cat_card_text text-center'>{ language == "ar" ?  item.name_ar :item.name_en }</div>
                  <div className='cat_card_image'>
                    <img src={item.image} alt='' />
                  </div>
                </div>
              );
            })
          : 
          <div className="d-flex align-items-center justify-content-center">

          <div class="spinner-border text-primary text-center" role="status">
          <span class="visually-hidden text-center">Loading...</span>
        </div> 
        </div> 
          }
      </div>
      <div className="after_first_four">
      {data && data.length >= 1 && Array.isArray(data)
          ? data?.slice(4)?.map((item, index) => {
              return (
                <div
                onClick={()=> navigate(`/cats_prods/${item?.category_id}`)}

                  className={`category_card small`}
                > 
                  <div className='cat_card_text'>{language == "ar" ?  item.name_ar :item.name_en}</div>
                  <div className='cat_card_image'>
                    <img src={item.image} alt='' />
                  </div>
                </div>
              );
            })
          : 
         ""
          }
      </div>

    </div>
  );
};

export default Categories;
