import React, { useEffect, useRef, useState } from "react";


import useSelection from "antd/es/table/hooks/useSelection";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BASEURL } from "../../API/BaseUrl";
import UseGeneral from "../../hooks/useGeneral";
import useUserId from "../../hooks/useUserId";
import { getRender } from "../../redux/rerednerSlice";
import { exitModal } from "./../../assets/svgIcons/svgIcons";
import "./style.css";
import ReactToPrint from "react-to-print";

const CartPage = () => {
  const [cartData, setCartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allMarkets, setAllMarkets] = useState([])
  const { language } = UseGeneral()
  const [searchParam, setSearchParam] = useSearchParams()
  const { userId } = useUserId()

  const getCartData = async () => {
    setLoading(true);
    const dataSet = {
      user_id: userId,
      language_type: language == "ar" ? "arabic" : "english"
    };
    await axios
      .post(
        `${BASEURL}products/select_user_favorite.php`,
        JSON.stringify(dataSet)
      )
      .then((res) => {
        if (res.data.status == "success") {


          const storedStores = JSON.parse(localStorage.getItem("ksbStores"))
          const newData = res?.data?.message.map(prod => {

            const prodDataPrices = JSON.parse((prod.prices)?.replace(/'/g, '"')).filter(price => {
              const isExist = storedStores?.find(store => store[language == "ar" ? "market_name_ar" : "market_name_en"] == price[0])
              if (isExist) {
                return true
              }
            }).map(item => {
              return {
                name: item[0],
                value: item[1],
              }
            }).map(item => {
              let itemData = storedStores.find(store => store[language == "ar" ? "market_name_ar" : "market_name_en"] == item.name)
              return {
                ...itemData,
                price: item.value
              }
            })

            return {
              ...prod,
              prices: prodDataPrices
            }

          })




          setCartData(newData);
          // const parsedData = 
          const allMarketsData = newData?.map(item => ({
            ...item,
            prices: item.prices.map(p_item => ({
              ...p_item,
              count: item.count_in_favorite
            }))
          })).map(data => data.prices).flat(1);

          const sums = {};
          allMarketsData.forEach(item => {
            const name = item[language == "ar" ? "market_name_ar" : "market_name_en"]; // Assuming you want to sum based on Arabic names
            const price = parseFloat(item.price);
            if (sums[name]) {
              sums[name] += parseInt(item?.count) * price;
            } else {
              sums[name] = parseInt(item?.count) * price;
            }
          });

          const result = Object.keys(sums).map(name => ({
            market_name_ar: name,
            price: sums[name].toFixed(2),
            // Ensuring the price is formatted as a string with 2 decimal places
          }));


          // console.log(result)
          setAllMarkets(result)



          // const storeSums = {};
          // allMarketsData.forEach(([store, value]) => {
          //   console.log(store)
          //   const numValue = parseFloat(value);
          //   if (storeSums[store]) {
          //     storeSums[store] += +numValue;
          //   } else {
          //     storeSums[store] = +numValue;
          //   }
          // });

          // const resultArray = Object.entries(storeSums);

          // setAllMarkets(resultArray)

          // console.log(allMarketsData)
        } else {
          toast.error("هناك مشكلة ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userId)
      getCartData();
  }, [userId]);

  const dispatch = useDispatch();
  const cartProdsData = useSelection((state) => state.cart.cart);

  const handleChangeCount = (action, id) => {
    const cartData = JSON.parse(localStorage.getItem("kspCart")) || [];

    if (action == "remove") {
      const newData = cartData.filter((item) => item.id != id);
      localStorage?.setItem("kspCart", JSON.stringify(newData));
      getCartData();
      return;
    }
    if (cartData) {
      const newData = cartData.map((item) => {
        if (item.id == id) {
          return {
            ...item,
            count: action == "add" ? +item.count + 1 : +item.count - 1,
          };
        }
        return item;
      });
      localStorage.setItem("kspCart", JSON.stringify(newData));
      getCartData();
    }
  };

  const changeCountFromCart = async (action, prod, count) => {
    setLoading(true);

    const dataSet = {
      product_code: prod.product_code,
      user_id: userId,
      count:
        action == "add" ? +count + 1 : action == "minus" ? +count - 1 : count,
      // every time send the new count if up or low
      // if you send zero the fav will be removed auto
    };

    await axios
      .post(`${BASEURL}products/add_edit_to_fav.php`, JSON.stringify(dataSet))
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          toast.success(language == "ar" ? "تمت تغيير الكمية بنجاح" : "Count has been changed successfully");
          getCartData();
          dispatch(getRender())



        } else {
          toast.error(language == "ar" ? "هناك مشكلة ما" : "There is a problem");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };
  const componentRef = useRef()
  return (<>

    <div className='cart_page' ref={componentRef}>
      <div className='cart_content'>

        {cartData && cartData.length >= 1 && Array.isArray(cartData) ? (
          <>
            {cartData?.map((proItem, index) => {
              console.log("proItem, ", proItem)
              return (
                <div key={index} className='cart_product_2'>
                  <div
                    className='remove_cart_button'
                    onClick={() => changeCountFromCart("remove", proItem, 0)}
                  >
                    {exitModal}
                  </div>

                  <div className='card_prod_top'>
                    <div className='cart_prod_image'>
                      <img src={proItem.caption} alt='' />
                    </div>
                    <div className='cart_prod_info'>
                      <h4>{proItem.name}</h4>
                      <h5>
                        {/* <span>{(+item.price * +item.count).toFixed(2)}</span>{" "} */}
                        <span>
                          {
                            (+proItem.count * +Math.min(...proItem.prices.map(price => price.price)))?.toFixed(2)
                          }
                          {" "}
                        </span>
                        {language == "ar" ? "ريال" : "SAR"}
                      </h5>
                    </div>
                    <div className='count_buttons'>
                      <div
                        className='count_btn'
                        onClick={() =>
                          changeCountFromCart("add", proItem, proItem.count)
                        }
                      >
                        +
                      </div>
                      <div className='prod_count'>{proItem?.count}</div>
                      <div
                        className={`count_btn ${proItem.count > 1 ? "" : "opacity-50"
                          }`}
                        onClick={() =>
                          +proItem.count > 1
                            ? changeCountFromCart(
                              "minus",
                              proItem,
                              proItem.count
                            )
                            : null
                        }
                      >
                        -
                      </div>
                    </div>
                  </div>
                  <table className='cart_page_table'>
                    <thead className='table_header'>
                      <tr>
                        {proItem.prices
                          .map((item, index) => {
                            return <th className='marketName'>{language == "ar" ? item?.market_name_ar : item.market_name_en}</th>;
                          })}
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        {proItem.prices
                          .map((item, index) => {
                            console.log("item", item)
                            return (
                              <th
                                className={`marketPrice ${+Math.min(
                                  ...proItem.prices.map(price => price.price)
                                ) == +item.price
                                  ? "active"
                                  : ""
                                  }`}
                                key={index}
                              >
                                {(proItem?.count_in_favorite * 1 * item.price * 1)?.toFixed(2)}
                              </th>
                            );
                          })}
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}

            <div className="fixed_subermarkets" style={{ display: "flex" }}>

              <table className='cart_page_table' >
                <thead className='table_header'>
                  <tr>
                    {allMarkets
                      .map((item, index) => {
                        return <th className='marketName'>{item.market_name_ar}</th>;
                      })}
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    {allMarkets
                      .map((item, index) => {
                        return (
                          <th
                            className={`marketPrice ${Math.min(...allMarkets.map(item => item.price)) == item.price ? "active" : ""

                              }`}
                            key={index}
                          >
                            {item?.price}
                          </th>
                        );
                      })}
                  </tr>
                </tbody>
              </table>
              <ReactToPrint
                trigger={() => {
                  return <span style={{ cursor: "pointer" }}> <svg xmlns="http://www.w3.org/2000/svg" width="2.8em" height="2.8em" viewBox="0 0 50 50"><g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}><path stroke="#306cfe" d="M35.604 33.333h6.063a2.083 2.083 0 0 0 2.083-2.083V14.583a2.083 2.083 0 0 0-2.083-2.083H8.333a2.083 2.083 0 0 0-2.083 2.083V31.25a2.083 2.083 0 0 0 2.083 2.083h6.063M35.417 6.25H14.583v6.25h20.834z"></path><path stroke="#344054" d="M37.5 43.75h-25l4.167-22.917h16.666z"></path></g></svg></span>
                }}
                content={() => componentRef.current}
              />


            </div>
            <div className="none_subermarkets">

              <table className='cart_page_table'>
                <thead className='table_header'>
                  <tr>
                    {allMarkets
                      .map((item, index) => {
                        return <th className='marketName'>{item.market_name_ar}</th>;
                      })}
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    {allMarkets
                      .map((item, index) => {
                        return (
                          <th
                            className={`marketPrice ${Math.min(...allMarkets.map(item => item.price)) == item.price ? "active" : ""

                              }`}
                            key={index}
                          >
                            {item?.price}
                          </th>
                        );
                      })}
                  </tr>
                </tbody>
              </table>



            </div>
          </>
        ) : (
          <div className='text-center my-4 fs-5 text-secondary'>
            {
              language == "ar" ? "لا يوجد منتجات في المفضلة" : "No products in favorits"
            }
          </div>
        )}
      </div>
    </div></>
  );
};

export default CartPage;
