import useSelection from "antd/es/table/hooks/useSelection";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./style.css";
import {useEffect, useState} from "react";
import ChooseCountry from "../../pages/ChooseCountry/ChooseCountry";
import UseGeneral from "../../hooks/useGeneral";
import {Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import Stores from "../../pages/Stores/Stores";
import StartStores from "../../pages/StartChooseMarket/StartChooseMaket";
import { ChevronLeft } from "../../assets/svgIcons/svgIcons";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
const DefaultLayout = ({classessName, children}) => {
  const [ksbCity, setKsbCity] = useState("");
  const [ksbStores, setKsbStores] = useState([]);
  const [ skip , setSkip] = useState(false);
  const {language} = UseGeneral();

  const getSelectedCity = (action) => {
    const selectedCity = JSON.parse(localStorage.getItem("ksbCity"));
    const storedStores = JSON.parse(localStorage.getItem("ksbStores"));
    setKsbCity(selectedCity);
    setKsbStores(storedStores)
  };

  const rerender = useSelector(state => state.rerender.rerender);
  

  useEffect(()=>{
    getSelectedCity()
    // localStorage.clear();
   },[rerender])

  const navigate = useNavigate();


   const location = useLocation()

   console.log(location)


  return (
    <div>
      {ksbCity && ksbStores ? (
        <>
        <Header />
          {children}
          <Footer />
        </>
      ) : (
        <>
        
        <div className='start_choose_city'>

          <Routes>
            <Route
              path='/chooseCountries'
              element={<ChooseCountry getSelectedCity={getSelectedCity} />}
            />

            <Route path="/store" element={<>
             <div className="">
              <StartStores  />
             </div>
            </>} />
            <Route path="*" 
              element={<ChooseCountry getSelectedCity={getSelectedCity} />}

            />
          </Routes>
        <Toaster containerClassName='toastCon'  />

        </div>
        </>

      )}
    </div>
  );
};

export default DefaultLayout;
